import Button from "@/components/button";
import Input from "@/components/input";
import Loader from "@/components/loader";
import uploadButton from "@/components/upload-button";
import bulkValid from "@/components/bulk-upload-valid";

import titleMixin from "@/mixins/titleMixin";
import {mapGetters, mapActions} from "vuex";
import axios from "@/axios";
import {uniqBy} from "lodash";
import moment from "moment";

const ErrorMessage = () => import("@/components/server-error-message");
// filepond imports
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
const FilePond = vueFilePond();

export default {
    name: "neo-products-list",
    components: {
        "neo-button": Button,
        "neo-input": Input,
        "neo-loader": Loader,
        ErrorMessage,
        "neo-upload-button": uploadButton,
        "neo-upload-valid": bulkValid,
        FilePond,
    },
    props: [],
    mixins: [titleMixin],
    title() {
        return `Products`;
    },
    data() {
        return {
            products: [],
            selectedProduct: null,
            loader: false,
            buttonDisable: false,
            files: [],
            myFiles: [],
            table_data: [],
        };
    },
    computed: {
        ...mapGetters(["getCaseId", "getCasePersonalData", "getCompanyProducts", "getLicenses", "checkPermissionsFetched"]),
        isAuth() {
            return this.getLicenses?.includes("case_create__case_data");
        },
        isCaseUploadAuth() {
            return this.getLicenses?.includes("case_create__bulk_upload_case");
        },
        allProducts() {
            return uniqBy(this.products, "key");
        },
    },
    async mounted() {
        this.loader = true;
        await this.fetchCompanyProducts();
        this.products = this.getCompanyProducts;
        this.loader = false;
    },
    methods: {
        ...mapActions(["fetchCompanyProducts"]),
        back() {
            path = "";
        },
        next() {
            const managed_service = this.selectedProduct?.product_type === "managed_service";

            if (managed_service) {
                // to do managed service here
                this.$store.dispatch("fetchCaseEntityInfo", this.selectedProduct.key);
                if (this.selectedProduct.automated) this.$router.push(`/automated/research/NEW/entities`);
                else this.$router.push(`/research/NEW/entities`);
                // this.$store.dispatch("setProduct", this.selectedProduct);
            } else {
                if (this.$route.path === "/case/product") this.createCase();
                else this.configureCase();
            }
        },
        handleProduct(product) {
            this.selectedProduct = product;
            this.$store.dispatch("setProduct", this.selectedProduct);

            // console.log('product :>> ', product);
            this.$store.commit("SET_CASE_MAX_ENTITY", {
                person: product?.entities_count ?? 1,
                company: product?.companies_count ?? 1,
            });
        },
        configureCase() {
            this.$store.dispatch("setProduct", this.selectedProduct);
            // this.$router.push({ path: "/case/configure", query: { product: this.selectedProduct.name } });
            this.$router.push({path: "/product/configure", query: {product: this.selectedProduct.name}});

            // this.createCase();
        },
        async createCase() {
            if (this.selectedProduct?.key) {
                this.loader = true;
                this.buttonDisable = true;
                const {data} = await axios.post(`/case-info/case-create`, {
                    due_diligence_level: this.selectedProduct.name,
                    automated: this.selectedProduct.automated,
                });

                if (data.case_id) {
                    this.$toast.success(`Case ${data.case_id} created.`);
                    this.loader = false;
                    this.buttonDisable = false;
                    // this.$router.push({path: `/research/${data.case_id}`}).catch(() => null)
                    if (this.selectedProduct.automated) window.open(`/automated/research/${data.case_id}`, "_self");
                    else window.open(`/research/${data.case_id}`, "_self");
                    sessionStorage.clear();
                    localStorage.clear();
                } else {
                    this.$toast.error("Something went wrong while creating new case.");
                    this.loader = false;
                    this.buttonDisable = false;
                }
            }
        },

        async getUploadedFiles() {
            this.files = [];
            try {
                let url = `/case-info/files`;
                const response = await axios.get(url);
                if (response?.status === 200)
                    this.files = response.data.map((file) => ({
                        ...file,
                        created_at: moment(file.created_at).format("YYYY-MM-DD"),
                    }));
                else this.$toast.error(response.data.message);
            } catch (error) {
                this.$toast.error(error.message || "something went wrong \n please try again");
            }
        },

        async downloadFile(id) {
            try {
                let url = `/case-info/files/${id}`;
                const response = await axios.get(url);
                if (response?.status === 200 && response.data?.status) window.open(response.data?.url, "");
                else this.$toast.error(response.data.message);
            } catch (error) {
                this.$toast.error(error.message || "something went wrong \n please try again");
            }
        },

        showCaseUploadModal() {
            this.$modal.show("file_upload");
            this.getUploadedFiles();
        },

        hideModal() {
            this.$modal.hide("file_upload");
        },

        cancelUpload() {
            this.$refs.file.value = "";
        },

        async uploadFile(valid = false) {
            let file = this.myFiles.map((fl) => fl.file);
            let fileExt = file.map((fl) => fl.name);
            let validExts = new Array(".xlsx", ".xls", ".csv");
            let header = {
                "Content-Type": "multipart/form-data",
            };
            // const filename = fileExt.join(" + ")
            fileExt = fileExt.map((fle) => fle.substring(fle.lastIndexOf(".")));
            if (!fileExt.every((fle) => validExts.includes(fle))) {
                this.$toast.error("Invalid file selected, valid files are of " + validExts.toString() + " types.");
                this.cancelUpload();
                return false;
            } else {
                let formData = new FormData();
                let excelFiles = file;
                // send multiple files as array
                excelFiles.forEach((efl) => {
                    formData.append("excel_file", efl);
                });
                // send multiple files as array
                // if (valid) {
                try {
                    let url = `/case-info/excel-workbook-validation`;
                    const response = await axios.post(url, formData, {
                        headers: header,
                    });
                    if (response?.status === 200 && response?.data?.message) {
                        if (response?.data?.status) {
                            this.$toast.success(response.data.message);
                        } else {
                            this.cancelUpload();
                            this.$toast.error(response.data.message);
                        }
                    }
                } catch (error) {
                    this.$toast.error(error.message || "something went wrong \n please try again");
                }
                this.cancelUpload();
                this.$modal.hide("upload_xl_valid");
                this.$modal.hide("file_upload");
                // }
                /// check for validation
                // else {
                //     try {
                //         let url = `/case-info/bulk-upload-check`;
                //         const response = await axios.post(url, formData, {
                //             headers: header
                //         })
                //         if (response?.status === 200 && response?.data?.status) {
                //             this.$modal.show('upload_xl_valid')
                //             this.table_data = response.data.data
                //         } else {
                //             this.$toast.error(response.data.message);
                //             this.cancelUpload()
                //         }
                //     } catch (error) {
                //         this.cancelUpload()
                //     }
                // }
            }
        },
    },
};
